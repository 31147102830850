import type { FC } from 'react'
import { Fragment, useMemo } from 'react'

import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { DaySummary } from 'features/Cart/components/DaySummary/DaySummary'

import type { IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartErrorList } from 'features/Cart/components/CartErrorList/CartErrorList'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { CartSlot } from 'features/Cart/components/CartSlot/CartSlot'
import type { IOptionSlot, IOptionSlotDay } from 'features/Option'

import styles from './cartSlot.module.scss'

interface IProps {
  options: IOptionSlot[]
  handleEdit: (option: ISiteRoom) => void
  handleRemoveOptionFixed?: (date: string, option: ISiteRoom) => void
  handleRemoveSlot?: (date: string, slot: IRoomTimeSlot, option: ISiteRoom) => void
  handleRemoveDay?: (date: string, option: ISiteRoom, slots: IRoomTimeSlot[]) => void
}

export const CartSlotList: FC<IProps> = ({
  options,
  handleEdit,
  handleRemoveSlot = () => {},
  handleRemoveOptionFixed = () => {},
  handleRemoveDay = () => {},
}) => {
  const groupedByOption = useMemo(() => {
    return options.reduce((acc, option) => {
      if (!acc[option.option.id]) {
        acc[option.option.id] = {
          option: option.option,
          price: option.price,
          dates: {},
        }
      }

      option.selectedDays.forEach((day) => {
        if (!acc[option.option.id].dates[day.date]) {
          acc[option.option.id].dates[day.date] = []
        }
        acc[option.option.id].dates[day.date].push(day)
      })

      return acc
    }, {} as Record<number, { option: ISiteRoom; price: number; dates: Record<string, IOptionSlotDay[]> }>)
  }, [options])

  return (
    <div className={styles.parent}>
      {Object.values(groupedByOption).map(({ option, price, dates }) => (
        <Fragment key={option.id}>
          <CartItem
            price={price}
            name={option.name}
            handleEdit={() => handleEdit(option)}
            img={option.images.at(0)}>
            {Object.entries(dates).map(([date, days]) => (
              <Fragment key={date}>
                <DaySummary
                  date={date}
                  onRemoveDay={() =>
                    handleRemoveDay(
                      date,
                      option,
                      days.flatMap((day) => day.slots || []),
                    )
                  }>
                  {days.map((day: IOptionSlotDay, index: number) => (
                    <CartSlot
                      key={index}
                      day={day}
                      option={option}
                      handleRemoveSlot={handleRemoveSlot}
                      handleRemoveOption={handleRemoveOptionFixed}
                    />
                  ))}
                </DaySummary>
              </Fragment>
            ))}

            <CartErrorList errorList={option.error_list} />
          </CartItem>
          <hr />
        </Fragment>
      ))}
    </div>
  )
}
