import type { ReactNode } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import LinkedInTag from 'react-linkedin-insight'
import { useNavigate } from 'react-router-dom'
import { useTurnstile } from 'react-turnstile'
import { useLocalStorage, useToggle } from 'react-use'

import { notification as antNotification } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'

import { INSIGHT_CONSTANTS } from 'common/constants/insightConstants'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppDispatch } from 'common/hooks/redux'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'
import type { IUser } from 'common/interfaces/IUser'
import { USER_ROLE } from 'common/interfaces/IUser'
import { APP_API_LIST } from 'common/services/stateService'

import { useLogoutAsDoctorMutation } from 'features/Admin/state/api/adminApi'
import { useLoginMutation, useLogoutMutation, useRegisterMutation } from 'features/Auth'
import type { IAuthLogin, IAuthRegister } from 'features/Auth/interfaces/IAuth'
import type { IUploadedValidationDocument } from 'features/Auth/interfaces/IAuthUploadedDocument'
import { PathUtils } from 'routes/routes.utils'
import { useFetchProfileQuery } from 'features/SettingsOld/state/api/settingsApi'

interface IProps {
  isDoctor: boolean
  isStaff: boolean
  hasCompletedDocuments: boolean
  hasIncompleteDocuments: boolean
  user: IUser | null
  stateUser: IUser | null
  error: string | null
  isLoading: boolean
  isSuperAdminAsDoctor: boolean
  isSuperStaff: boolean
  logout: () => void
  resetError: () => void
  login: (data: IAuthLogin) => Promise<void>
  loginAsDoctor: (data: any) => void
  register: (data: IAuthRegister) => Promise<boolean>
  updateName: (first_name: string, last_name: string) => void
  updatePhoneNumber: (phone_number: string) => void
  updateDocuments: (documents: IUploadedValidationDocument[]) => void
  logoutAsDoctor: () => void
  setUser: (user: IUser | null) => void
  isSidebarVisible?: boolean
  toggleIsSidebarVisible?: () => void
}

const AuthContext = createContext<IProps>(null)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [doLogout]: ITriggerRequest = useLogoutMutation()
  const [logOutAsDoctor] = useLogoutAsDoctorMutation()
  const [doLogin, { isLoading: isLoadingLogin }]: ITriggerRequest = useLoginMutation()
  const [doRegister, { isLoading: isLoadingRegister }]: ITriggerRequest = useRegisterMutation()
  const turnstile = useTurnstile()

  const [error, setError] = useState<string | null>(null)
  const [localStorageUser, setLocalStorageUser] = useLocalStorage<IUser | null>(
    LOCALE_STORAGE_KEYS.USER,
    null,
  )
  const [stateUser, setStateUser] = useState<IUser | null>(null)
  const [isSidebarVisible, toggleIsSidebarVisible] = useToggle(false)

  const { data: profile } = useFetchProfileQuery(null, { skip: !stateUser })

  const logout = (): void => {
    doLogout()
    handleSetUser(null)
    window.localStorage.clear()
    navigate(PATH_SEGMENT.LOGIN, { replace: true })
    APP_API_LIST.forEach((reducer): void => {
      dispatch(reducer.util.resetApiState())
    })
    antNotification.destroy()
  }

  const login = async (data: IAuthLogin): Promise<void> => {
    LinkedInTag.track(INSIGHT_CONSTANTS.LOGIN)
    TagManager.dataLayer({ dataLayer: { event: INSIGHT_CONSTANTS.LOGIN } })
    const response = await doLogin(data)
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.ERROR)) {
      setError(response.error.data[0])
      turnstile?.reset()
    } else {
      const { data } = response
      const newUser = {
        ...data.profile,
        access_token: data.access_token,
        documents: data.documents,
        is_super_doctor: data.is_super_doctor,
      }
      handleSetUser(newUser)
      const isValidUser =
        data.documents?.length >= 2 &&
        data.documents.every((documentUploaded: IUploadedValidationDocument): boolean =>
          Boolean(documentUploaded.is_valid),
        )

      if (isValidUser || data?.profile?.role?.name === USER_ROLE.STAFF) {
        navigate(PATH_SEGMENT.ROOT)
      } else {
        navigate(PathUtils.getValidateIdentity())
      }
    }
  }

  const loginAsDoctor = async (data: any): Promise<void> => {
    const { profile, documents, access_token, old_access_token } = data
    const newUser = {
      ...profile,
      access_token,
      documents,
      old_access_token,
    }
    handleSetUser(newUser)
    navigate(PATH_SEGMENT.ROOT)
    window.location.reload()
  }

  const logoutAsDoctor = async (): Promise<void> => {
    try {
      const response = await logOutAsDoctor(localStorageUser?.old_access_token)
      const { data } = response
      const { profile, documents, access_token, is_super_doctor } = data
      const newUser = {
        ...profile,
        access_token,
        documents,
        is_super_doctor,
      }
      handleSetUser(newUser)
      navigate(PATH_SEGMENT.ROOT)
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  const resetError = (): void => {
    setError(null)
  }

  const register = async (registerData: IAuthRegister): Promise<boolean> => {
    LinkedInTag.track(INSIGHT_CONSTANTS.REGISTER)
    TagManager.dataLayer({ dataLayer: { event: INSIGHT_CONSTANTS.REGISTER } })
    const response = await doRegister(registerData)
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.ERROR)) {
      setError(response.error.data[0])
      turnstile?.reset()
      return false
    }
    return true
  }

  const updateDocuments = (documents: IUploadedValidationDocument[]): void => {
    const newUser = { ...localStorageUser, documents }
    handleSetUser(newUser)
  }

  const updateName = (first_name: string, last_name: string): void => {
    if (localStorageUser?.first_name !== first_name || localStorageUser?.last_name !== last_name) {
      const newUser = { ...localStorageUser, first_name, last_name }
      handleSetUser(newUser)
    }
  }

  const updatePhoneNumber = (phone_number: string): void => {
    const newUser = { ...localStorageUser, phone_number }
    handleSetUser(newUser)
  }

  function handleSetUser(newUser: IUser | null): void {
    setLocalStorageUser(newUser)
    setStateUser(newUser)
  }

  const value = useMemo(() => {
    const hasCompletedDocuments =
      localStorageUser?.documents?.length >= 2 &&
      localStorageUser?.documents.every(({ is_valid }: IUploadedValidationDocument) =>
        Boolean(is_valid),
      )

    const hasIncompleteDocuments =
      localStorageUser?.documents?.length >= 2 &&
      localStorageUser?.documents.some(
        ({ is_valid }: IUploadedValidationDocument) => is_valid === null,
      )

    const isSuperStaff =
      !!profile?.allow_create_booking && localStorageUser?.role?.name === USER_ROLE.STAFF

    return {
      user: localStorageUser,
      stateUser: stateUser,
      error,
      isLoading: isLoadingRegister || isLoadingLogin,
      isDoctor: localStorageUser && localStorageUser.role.name === USER_ROLE.DOCTOR,
      isStaff: localStorageUser && localStorageUser.role.name === USER_ROLE.STAFF,
      isSuperAdminAsDoctor: Boolean(localStorageUser?.old_access_token),
      hasCompletedDocuments,
      hasIncompleteDocuments,
      isSidebarVisible,
      isSuperStaff,
      toggleIsSidebarVisible,
      login,
      loginAsDoctor,
      logout,
      register,
      resetError,
      updateName,
      updatePhoneNumber,
      updateDocuments,
      logoutAsDoctor,
      setUser: handleSetUser,
    }
  }, [localStorageUser, error, isLoadingRegister, isLoadingLogin, isSidebarVisible, stateUser])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext) as IProps
}
